<template>
    <div id='id'>
        客户列表
    </div>
</template>

<script>
export default {
    name: 'customerList'

}
</script>

<style scoped>

</style>