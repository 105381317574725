<template>
    <div id='id'>
        我的订单
    </div>
</template>

<script>
export default {
    name: 'myOrder'

}
</script>

<style scoped>

</style>