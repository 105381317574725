import { render, staticRenderFns } from "./myOrder.vue?vue&type=template&id=d8d9ded2&scoped=true"
import script from "./myOrder.vue?vue&type=script&lang=js"
export * from "./myOrder.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8d9ded2",
  null
  
)

export default component.exports