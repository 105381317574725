<template>
    <div id='id'>
        公海客户
    </div>
</template>

<script>
export default {
    name: 'commonCustomer'

}
</script>

<style scoped>

</style>